<template>
    <Button label="Kembali" icon="pi pi-angle-left" class="p-button-link p-button-rounded p-button-success mb-2" @click="this.$router.go(-1)"/>
	<div class="grid fadein animation-duration-300">
		<div class="col-12">
			<div class="card text-center">
				<h3>Ganti Password</h3>
				<form class="p-fluid flex justify-content-center">
					<fieldset :disabled="loadingRegister">
						<div class="form-group">
							<div class="field">
								<label for="password">Password Lama</label>
								<Password id="password" v-model="current_password"  toggleMask :feedback="false" :class="{'p-invalid':submitted && !current_password || errors.current_password}" />
								<small class="p-error" v-if="submitted && !current_password">current_password harus diisi.</small>
								<InlineMessage  v-if="errors && errors.current_password"><strong class="p-error">{{ errors.current_password[0] }}</strong></InlineMessage>
							</div>
							<div class="field">
								<label for="password">Password Baru</label>
								<Password id="password" v-model="new_password"  toggleMask :feedback="false" :class="{'p-invalid':submitted && !new_password}" />
								<small class="p-error" v-if="submitted && !new_password">new_password harus diisi.</small>
								<InlineMessage  v-if="errors && errors.new_password"><strong class="p-error">{{ errors.new_password[0] }}</strong></InlineMessage>
							</div>
							<div class="field">
								<label for="new_confirm_password">Konfirmasi Password*</label>
								<Password id="new_confirm_password" v-model="new_confirm_password"  toggleMask :feedback="false" :class="{'p-invalid':submitted && !new_confirm_password || new_password != new_confirm_password}" />
								<small class="p-error" v-if="submitted && !new_confirm_password">Konfirmasi password harus diisi.</small>
								<small class="p-error" v-if="new_password != new_confirm_password">Konfirmasi password tidak sesuai.</small>
							</div>
						</div>
					</fieldset>
				</form>
				<Button label="Simpan" icon="pi pi-check" class="p-button-round" @click="gantiPassword" :disabled="!current_password || !new_password || new_password != new_confirm_password || loadingRegister " />
			</div>
		</div>
	</div>
	<Dialog header="Ganti Password Berhasil" v-model:visible="gantiPasswordBerhasil" :breakpoints="{ '960px': '80vw' }" :style="{ width: '30vw' }" modal draggable ariaCloseLabel :closable="false">
        <div class="flex align-items-center flex-column pt-6 px-3">
            <i class="pi pi-exclamation-circle" :style="{fontSize: '5rem', color: 'var(--green-500)' }"></i>
            <h5>Berhasil mengganti password</h5>
            <p :style="{lineHeight: 1.5, textIndent: '1rem'}" class="text-justify">
                Anda akan diarahkan ke halaman sebelumnya.
            </p>
        </div>
    </Dialog>
</template>

<script>
	import User from '../api/User'
	export default {

		name: 'DonasiSukses',
        created() {
		},
		data() {
			return {
				form: [],
				current_password: null,
				new_password: null,
				new_confirm_password: null,
				submitted: false,
				loadingRegister: false,
				errors: {},
				gantiPasswordBerhasil: false,
			}
		},
		methods: {
			gantiPassword() {
                this.submitted = true;
                this.loadingRegister = true
                this.form = {
                    current_password: this.current_password,
					new_password: this.new_password,
					new_confirm_password: this.new_confirm_password,
                }
                User.changePassword(this.form)
                    .then(async response => {
                        this.loadingRegister = false;
						this.gantiPasswordBerhasil = true;
						this.errors = {};
                        this.form = {
							current_password: null,
							new_password: null,
							new_confirm_password: null,
						}
						setTimeout(this.$router.go, 3000, -1);
                    })
                    .catch(error => {
                        this.loadingRegister = false;
                        if (!error.response) {
                            this.networkError()
                        } else if(error.response.status == 422) {
                            this.errors = error.response.data.errors
                        }
                    }).finally(() => {
                        this.loadingRegister =  false;
                    });
            },
		},
	}
</script>